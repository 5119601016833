@tailwind base;
@tailwind components;
@tailwind utilities;

/* Vanilla CSS
". used to ref a class"
.class1{color:red};
 for h2 element, eg. span, p, div
h2{color:green}; */

/* Tailwind CSS
"if I write @apply do the following"*/
h6 {
  @apply font-bold text-2xl;
}

.spanButton {
  @apply relative px-5 py-1 transition-all ease-in duration-75 bg-white dark:bg-white rounded-md group-hover:bg-opacity-0;
}

.navAlign {
  @apply flex justify-center m-2 slide-up;
}

/***** Global Style *****/

/***** Global Slide *****/
.slide-right,
.slide-left {
  width: 100%;
}

/***** Slide Right *****/
.slide-right {
  animation: 3s slide-right;
}
@keyframes slide-right {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0%;
  }
}

/***** Slide Left *****/
.slide-left {
  animation: 3s slide-left;
}
@keyframes slide-left {
  from {
    margin-left: 100%;
  }
  to {
    margin-left: 48;
  }
}

.slide-up {
  animation: 3s slide-up;
}
@keyframes slide-up {
  from {
    margin-bottom: 100%;
  }
  to {
    margin-top: 0%;
  }
}

/***** Misc Styles *****/
body {
  background: #ffffff;
}

/***** FadeIn Underline *****/
.line {
  border-bottom: 3px solid red;
  width: 200px;
  margin: auto;
}
.fade-in {
  animation: fadeIn ease 3s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* SVG Layers */

.custom-shape-divider-bottom-waves {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  pointer-events: none;
}

.custom-shape-divider-bottom-waves svg {
  position: relative;
  display: block;
  width: calc(300% + 1.3px);
  height: 144px;
  pointer-events: none;
}

.custom-shape-divider-bottom-waves .shape-fill {
  fill: #67d1e649;
  pointer-events: none;
}

.custom-shape-divider-top-waves {
  position: absolute;
  top: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  /* transform: rotate(180deg); */
  pointer-events: none;
}

.custom-shape-divider-top-waves svg {
  position: relative;
  display: block;
  width: calc(300% + 1.3px);
  height: 144px;
  pointer-events: none;
}

.custom-shape-divider-top-waves .shape-fill {
  fill: #67d1e649;
  pointer-events: none;
}

.footer {
  position: fixed;
  left: 0;
  bottom: -2%;
  width: 100%;
  pointer-events: none;
}

.header {
  position: fixed;
  left: 0;
  top: -2%;
  width: 100%;
  pointer-events: none;
}

@media screen and (min-width: 1024px) {
  .banner-resize {
    margin-left: 12rem;
    margin-right: 12rem;
  }
}
@media screen and (min-height: 640px) {
  .banner-resize-ls {
    margin-top: 14rem;
  }
}
